export const translationEN = {
  heroTitle: "Hourly Rate Calculator",
  heroSubTitle: "<citation>Time is money</citation> as Benjamin Franklin Said.",
  labelPrice: "Price",
  timePlaceholder: "HH:MM",
  labelTime: "Time",
  labelCurrency: "Currency",
  labelExchangeRate: "Exchange rate",
  totalPrice: "Total price",
  btnResult: "Result",
  addToInvoice: "Add to invoice",
  btnCreateInvoice: "Create Invoice",
  priceError: "Rate should be a positive number",
  timeError: "Time should be in format hours:minutes. Example: 160:47",
  manualOptionExchangeSource: "Manual",
  badRequestApi: "Request Failed. Rates were not updated properly 🤪",
  noPreviewSupportedMessage: "Preview is not supported for this resolution",
  invoice: "Invoice",
  switchLang: "Switch language",
  changeTheme: "Change theme",
  remove: "Remove",
  addService: "Add service",
  save: "Save",
  edit: "Edit",
  exportToPDFText: "Export to PDF",
  closeButtonText: "Close",
};

export const translationUA = {
  heroTitle: "Погодинний калькулятор оплати",
  heroSubTitle:
    "<citation>Час - це гроші</citation>, як сказав Бенджамін Франклін",
  labelPrice: "Вартість",
  timePlaceholder: "ГГ:ХВ",
  labelTime: "Час",
  labelCurrency: "Валюта",
  labelExchangeRate: "Курс валют",
  totalPrice: "Загальна вартість",
  btnResult: "Розрахувати",
  addToInvoice: "Додати в інвойс",
  btnCreateInvoice: "Створити інвойс",
  priceError: "Вартість має бути позитивним числом",
  timeError: "Час має бути в форматі ходини:хвилини. Наприклад: 160:47",
  manualOptionExchangeSource: "Свій курс",
  badRequestApi: "Не вдалося отримати корректний курс валют",
  noPreviewSupportedMessage: "Попередній перегляд не підтримується",
  invoice: "Інвойс",
  switchLang: "Переключити мову",
  changeTheme: "Змінити тему",
  remove: "Видалити",
  addService: "Додати послугу",
  save: "Зберегти",
  edit: "Редактировать",
  exportToPDFText: "Експортувати в PDF",
  closeButtonText: "Завершити",
};
